<template>
  <div class="receipt-container">
    <z-card
      width="100%"
      :style="{
        border: $vuetify.breakpoint.smAndDown
          ? ''
          : '1px solid var(--lightgreen-600)',
      }"
    >
      <template #content>
        <div class="d-flex align-center gap-5">
          <z-icon color="var(--lightgreen-600)">$check_circle_rounded</z-icon>

          <p class="body-bold">
            {{ $t("warrantyRegister.processing_payment") }}
          </p>
        </div>
      </template>
    </z-card>

    <expenses-card
      :check-in-date="expensesInfo.checkInDate"
      :check-out-date="expensesInfo.checkOutDate"
      :expense-items="expensesInfo.expenseItems"
      :hotel-name="expensesInfo.hotelName"
      :reservation-number="expensesInfo.reservationNumber"
    >
      <template #top>
        <p class="card-title">
          {{ $t("warrantyRegister.receipt_of_payment") }}
        </p>
        <p class="body-text">
          {{ $t("warrantyRegister.processing_payment_by_card_company") }}
        </p>
        <p class="body-bold">
          {{ $t("warrantyRegister.email_confirmation_description") }}
        </p>
        <p class="body-text">
          {{ $t("warrantyRegister.you_can_close_window") }}
        </p>
      </template>
    </expenses-card>
  </div>
</template>

<script>
import ExpensesCard from "./ExpensesCard.vue"

export default {
  components: { ExpensesCard },
  props: {
    expensesInfo: {
      type: Object,
      default: () => ({
        checkInDate: "",
        checkOutDate: "",
        hotelName: "",
        reservationNumber: "",
        expenseItems: [],
      }),
    },
  },
}
</script>

<style lang="scss" scoped>
.receipt-container {
  display: grid;
  gap: 15px;
}
</style>
