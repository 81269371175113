<template>
  <processing-payment
    :reservation-info="reservation"
    :hotel-info="hotel"
    :order-info="order"
    :is-loading="isLoadingNext"
    @click:ok="onClickOk"
  ></processing-payment>
</template>

<script>
export default {
  beforeRouteEnter: (_, from, next) => {
    if (from.name === "home") next(false)
    else next()
  },
}
</script>

<script setup>
import { useNamespacedState } from "vuex-composition-helpers"

import { useStoreAction } from "@/composables"

import ProcessingPayment from "./components/ProcessingPayment.vue"

const { next, isLoading: isLoadingNext } = useStoreAction("flow/guest", "next")
const { reservation } = useNamespacedState("reservation", ["reservation"])
const { hotel } = useNamespacedState("company", ["hotel"])
const { order } = useNamespacedState("warranty/payment", ["order"])

const onClickOk = async () => await next()
</script>

<style></style>
