<template>
  <z-card-modal
    persistent
    :title="$t('warrantyRegister.thanks_for_using_our_platform')"
    :opened="isOpened"
    :width="$vuetify.breakpoint.smAndDown ? 350 : 400"
  >
    <template #content>
      <div class="modal-content">
        <div class="loading-container">
          <animated-loading
            :height="120"
            :is-loading="loadingAnimation"
          ></animated-loading>
        </div>

        <p class="body-text text-center">
          {{ $t("warrantyRegister.end_of_filling") }}
        </p>

        <p class="body-bold text-center">
          {{ $t("warrantyRegister.you_will_be_redirected") }}
        </p>

        <z-btn
          text="ok"
          primary
          :is-loading="isLoading"
          @click="$emit('click:ok')"
        ></z-btn>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
import { nextTick, ref, toRefs, watch } from "vue"

export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { isOpened } = toRefs(props)
    let loadingAnimation = ref(true)

    watch(isOpened, async (opened) => {
      if (opened) {
        await nextTick()
        loadingAnimation.value = false
      }
    })

    return {
      loadingAnimation,
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: grid;
  margin-top: 15px;
  gap: 15px;

  .loading-container {
    display: grid;
    justify-content: center;
  }

  .buttons-container {
    display: grid;
    gap: 15px;
  }
}

@media screen and (min-width: 768px) {
  .modal-content .buttons-container {
    grid-template-columns: min-content min-content;
    justify-content: flex-end;
  }
}
</style>
