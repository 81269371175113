<template>
  <z-card
    width="100%"
    height="fit-content"
    :shadow="$vuetify.breakpoint.mdAndUp"
  >
    <template #content>
      <div class="expenses-wrapper">
        <slot name="top">
          <div v-if="$vuetify.breakpoint.smAndDown" class="card-title">
            {{ $t("warrantyRegister.expense_items") }}
          </div>
          <h3 v-else>{{ $t("warrantyRegister.expense_items") }}</h3>
        </slot>
        <v-divider v-if="$vuetify.breakpoint.smAndDown"></v-divider>

        <div class="expenses-info">
          <div class="body3-bold">{{ hotelName }}</div>
          <div class="body3-bold">
            {{ $t("warrantyRegister.reservation_number") }}:
            {{ reservationNumber }}
          </div>

          <div class="body3"><b>Checkin</b> {{ checkInDate }}</div>
          <div class="body3"><b>Checkout</b> {{ checkOutDate }}</div>

          <template v-if="$vuetify.breakpoint.smAndDown">
            <template v-for="({ name, amount }, index) in formatedExpenseItems">
              <div :key="name + index" class="body3">
                {{ name }}
              </div>
              <div :key="amount + index" class="body3 item-value">
                {{ amount }}
              </div>
            </template>
          </template>
        </div>

        <v-divider></v-divider>

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <div class="itens-descriptions">
            <div class="card-title">{{ $t("warrantyRegister.items") }}</div>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <div class="card-title">{{ $t("warrantyRegister.value") }}</div>
          </div>

          <template>
            <div
              v-for="({ name, amount }, index) in formatedExpenseItems"
              :key="index"
              class="itens-descriptions"
            >
              <div class="body3">{{ name }}</div>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <div class="body3 item-value">{{ amount }}</div>
            </div>
          </template>

          <v-divider></v-divider>
        </template>

        <div class="total">
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
          <span class="body-bold">TOTAL</span>
          <span class="body3">{{ totalExpenseItems }}</span>
        </div>
      </div>
    </template>
  </z-card>
</template>

<script>
export default {
  name: "ExpensesCard",
  props: {
    hotelName: {
      type: String,
      default: "Zoox-Hotel",
    },

    reservationNumber: {
      type: [String, Number],
      default: "111111111",
    },

    checkInDate: {
      type: [String, Date],
      default: "00/00/00",
    },
    checkOutDate: {
      type: [String, Date],
      default: "00/00/00",
    },

    expenseItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    formatedExpenseItems() {
      return this.expenseItems.map((item) => {
        return {
          ...item,
          amount: new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(item.amount),
        }
      })
    },

    totalExpenseItems() {
      let total = this.expenseItems.reduce((acc, item) => item.amount + acc, 0)

      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(total)
    },
  },
}
</script>

<style lang="scss" scoped>
.expenses-wrapper {
  display: grid;
  gap: 15px;
}

@media screen and (max-width: 960px) {
  .expenses-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 7px 15px;

    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:nth-of-type(even) {
        text-align: end;
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 960px) {
  .expenses-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-content: space-between;
    gap: 15px;
  }

  .itens-descriptions,
  .total {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
